import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormHelperText,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import { InputField } from '../components/input-field';
import { Logo } from '../components/logo';
import { useSettings } from '../contexts/settings-context';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';
import gtm from '../lib/gtm';

export const PasswordComplete = (params) => {
  const { user, email } = params;
  const mounted = useMounted();
  const { completeNewPassword } = useAuth();
  const { settings } = useSettings();
  const formik = useFormik({
    initialValues: {
      email: email || '',
      newPassword: '',
      passwordConfirm: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup
        .string()
        .min(8, 'Must be at least 8 characters, containing lowercase, uppercase, and numbers')
        .max(255)
        .required('Required'),
      passwordConfirm: Yup
        .string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required')
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await completeNewPassword(user, email, values.newPassword);
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }
    }
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Password Complete | Carpatin Dashboard</title>
      </Helmet>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Container maxWidth="md">
          <Toolbar
            disableGutters
            sx={{ height: 64 }}
          >
            <RouterLink to="/">
              <Logo variant={settings.theme === 'dark' ? 'light' : 'dark'} />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Alert
              severity="success"
              sx={{ mb: 3 }}
              variant="filled"
            >
              You must choose new password to complete registration for your account
            </Alert>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Card
                  sx={{ backgroundColor: 'background.default' }}
                  elevation={0}
                >
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          mb: 3
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="h4"
                        >
                          Complete registration
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                      </Box>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <InputField
                            autoFocus
                            disabled
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formik.values.email}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <InputField
                            error={Boolean(formik.touched.newPassword
                              && formik.errors.newPassword)}
                            fullWidth
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                            label="New password"
                            name="newPassword"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.newPassword}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <InputField
                            error={Boolean(formik.touched.passwordConfirm
                              && formik.errors.passwordConfirm)}
                            fullWidth
                            helperText={formik.touched.passwordConfirm
                            && formik.errors.passwordConfirm}
                            label="Password confirmation"
                            name="passwordConfirm"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.passwordConfirm}
                          />
                        </Grid>
                        {formik.errors.submit && (
                          <Grid
                            item
                            xs={12}
                          >
                            <FormHelperText error>
                              {formik.errors.submit}
                            </FormHelperText>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                        >
                          <Button
                            color="primary"
                            disabled={formik.isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
