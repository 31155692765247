import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const initialSession = {
  controlDate: new Date(),
  pageControllers: {}
};

export const restoreSession = () => {
  let session = null;

  try {
    const storedData = window.sessionStorage.getItem('session');

    if (storedData) {
      session = JSON.parse(storedData);
    } else {
      session = initialSession;
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a stringified JSON this will fail,
    // that's why we catch the error
  }

  return session;
};

export const storeSession = (session) => {
  window.sessionStorage.setItem('session', JSON.stringify(session));
};

export const SessionContext = createContext({
  session: initialSession,
  saveSession: () => { }
});

export const SessionProvider = (props) => {
  const { children } = props;
  const [session, setSession] = useState(initialSession);

  useEffect(() => {
    const restoredSession = restoreSession();

    if (restoredSession) {
      setSession(restoredSession);
    }
  }, []);

  const saveSession = (updatedSession) => {
    setSession(updatedSession);
    storeSession(updatedSession);
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        saveSession
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SessionConsumer = SessionContext.Consumer;

export const useSession = () => useContext(SessionContext);
