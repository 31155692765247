import { format } from 'date-fns';

export const getLocalDate = (date) => {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - (offset * 60 * 1000));
  return localDate.toISOString().split('T')[0];
};

export const toLocalDate = (date) => {
  const offsetMins = new Date().getTimezoneOffset(); // offset for UTC+1 will be -60
  return new Date(date.getTime() - (offsetMins * 60 * 1000));
};

export const timeFromSeconds = (seconds) => {
  const date = new Date(0);
  date.setSeconds(seconds);
  const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return format(utc, 'HH:mm');
};

export const toDurationTimeString = (seconds) => {
  if (seconds) {
    const date1 = new Date(0);
    const date2 = new Date(0).setSeconds(seconds);

    let delta = Math.abs(date2 - date1) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const daysString = (days > 0) ? `${days}d ` : '';
    // eslint-disable-next-line no-nested-ternary
    const hoursString = (hours > 0) ? `${hours}h ` : (days > 0) ? '0h' : '';
    const minutesString = (minutes > 0) ? `${minutes}min` : '';

    console.log(`hours:${hours}, minutes:${minutes} seconds: ${seconds} --> `);
    return `${daysString}${hoursString}${minutesString}`;
  }
  return '';
};
