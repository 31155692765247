export const statusColors = {
  arrived: '#3a3a3a',
  'at warehouse': '#a3a3a3',
  cancelled: '#a3a3a3',
  completed: '#67c067',
  deleted: '#a3a3a3',
  failed: '#de5e59',
  'heading to': '#02008b',
  'in progress': '#347ab6',
  'info received': '#a3a3a3',
  'on hold': '#9b58b3',
  'out for collection': '#02008b',
  'out for delivery': '#02008b',
  'partially completed': '#f2b658',
  'pre-job': '#66c7e2',
  return: '#16a589',
  scheduled: '#66c7e2'
};

export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#5046e4'
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#5046e4'
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#a53531',
  light: '#ef6f6b',
  main: '#ec4c47'
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975b1e',
  light: '#e09b55',
  main: '#d9822b'
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0b4e8d',
  light: '#3f8cd4',
  main: '#1070CA'
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#1b774d',
  light: '#52bb8b',
  main: '#27AB6E'
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#ffffff'
};

export const lightText = {
  primary: '#03060b',
  secondary: '#506176'
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#b7bdcc',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#1e212a'
};

export const darkPrimary = {
  contrast: '#ffffff',
  dark: '#5659b2',
  light: '#9599ff',
  main: '#7b80ff'
};

export const darkSecondary = {
  contrast: '#ffffff',
  dark: '#5659b2',
  light: '#9599ff',
  main: '#7b80ff'
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#eb6d57',
  main: '#e6492d'
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#8D682d',
  light: '#f7bb57',
  main: '#f6ab2e'
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#1D4580',
  light: '#4483df',
  main: '#1664d8'
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#2F6b39',
  light: '#5fc16d',
  main: '#38b249'
};

export const darkBackground = {
  default: '#111318',
  paper: '#111318'
};

export const darkText = {
  primary: '#d8dade',
  secondary: '#868998'
};

export const darkNeutral = {
  100: '#1e212a',
  200: '#2b2f3c',
  300: '#40444f',
  400: '#545863',
  500: '#6c6f7f',
  600: '#868998',
  700: '#a1a4af',
  800: '#bdbfc7',
  900: '#d8dade'
};
