import 'simplebar/dist/simplebar.min.css';
import 'nprogress/nprogress.css';
import 'leaflet/dist/leaflet.css';
import './css/styles.css';
import 'devextreme/dist/css/dx.light.compact.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import fiLocale from 'date-fns/locale/fi';
import { AuthProvider } from './contexts/amplify-auth-context';
import { DepotsProvider } from './contexts/depot-context';
import { SettingsProvider } from './contexts/settings-context';
import { SessionProvider } from './contexts/session-context';
import { App } from './app';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={fiLocale}
        >
          <SettingsProvider>
            <SessionProvider>
              <AuthProvider>
                <DepotsProvider>
                  <App />
                  <Toaster position="bottom-right" />
                </DepotsProvider>
              </AuthProvider>
            </SessionProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root')
);
