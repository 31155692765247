import fetchApi from './backend/fetch-api';
import { applySort } from '../utils/apply-sort';
import { applyPagination } from '../utils/apply-pagination';
import { getLocalDate } from '../utils/date';
import { wait } from '../utils/wait';

class JobApi {
  async getJobsOrderedForRoute(route) {
    if (route.has_assigned_vehicle) {
      return fetchApi({
        endpoint: 'admin/jobs/ordered',
        params: {
          assigned_vehicle: route.assigned_vehicle,
          date: route.date
        }
      });
    }
    return fetchApi({
      endpoint: 'admin/jobs/ordered',
      params: {
        run_numbers: route.vehicle_routes.split(',').map((s) => s.trim()),
        date: route.date
      }
    });
  }

  async getUnroutedJobs(params) {
    const {
      page,
      pageSize,
      sort,
      sortBy,
      filterGroups,
      filterStatuses,
      filterDoNumber,
      filterCustomer,
      filterAddress,
    } = params;

    const filterParams = { ...params };
    if (filterParams.filterUnassigned === 'all') {
      delete filterParams.filterUnassigned;
    }

    const allJobs = await fetchApi({ endpoint: 'admin/jobs/unrouted', params: filterParams });
    const groupNames = allJobs.map((j) => j.group_name || '');
    const statuses = allJobs.map((j) => j.tracking_status);

    let jobs = allJobs;
    if (filterGroups && filterGroups.length > 0) {
      jobs = jobs.filter((j) => filterGroups.includes(j.group_name));
    }
    if (filterStatuses && filterStatuses.length > 0) {
      jobs = jobs.filter((j) => filterStatuses.includes(j.tracking_status));
    }
    if (filterDoNumber && filterDoNumber.length > 0) {
      jobs = jobs.filter((j) => j.do_number
        && j.do_number.toLowerCase().includes(filterDoNumber.toLowerCase()));
    }
    if (filterCustomer && filterCustomer.length > 0) {
      jobs = jobs.filter((j) => j.deliver_to_collect_from
        && j.deliver_to_collect_from.toLowerCase().includes(filterCustomer.toLowerCase()));
    }
    if (filterAddress && filterAddress.length > 0) {
      jobs = jobs.filter((j) => j.full_address
        && j.full_address.toLowerCase().includes(filterAddress.toLowerCase()));
    }

    const sortedJobs = applySort(jobs, sort, sortBy);
    const paginatedJobs = applyPagination(sortedJobs, page, pageSize);

    return Promise.resolve({
      jobs: paginatedJobs,
      jobsCount: jobs.length,
      groupOptions: [...new Set(groupNames)].sort(),
      statusOptions: [...new Set(statuses)].sort()
    });
  }

  async updateJobAddress(job, params) {
    const jobUpdate = { data: { ...params } };
    const updateResponse = await fetchApi({
      endpoint: `jobs/${job.do_number}/${job.date}`,
      method: 'PUT',
      body: JSON.stringify(jobUpdate)
    });
    return updateResponse.data;
  }

  async updateJobDepot(job, params) {
    const jobUpdate = { data: { ...params } };
    const updateResponse = await fetchApi({
      endpoint: `jobs/${job.do_number}/${job.date}`,
      method: 'PUT',
      body: JSON.stringify(jobUpdate)
    });
    return updateResponse.data;
  }

  async resetExistingRouteInformation(params) {
    const request = {
      date: getLocalDate(params.planDate),
      optimization_group_id: params.optimizationGroupId
    };
    if (params.groupTimeWindows && params.groupTimeWindows.length > 0) {
      request.group_time_windows = params.groupTimeWindows.map((gtw) => ({
        group_name: gtw.groupName,
        tw_start: gtw.twStart,
        tw_end: gtw.twEnd
      }));
    }
    const process = await fetchApi({
      endpoint: 'admin/jobs/reset_routes',
      method: 'POST',
      body: JSON.stringify(request)
    });
    await this.waitRouteInfoResetProcessing(process.id);
    await fetchApi({
      endpoint: `admin/jobs/reset_routes/${process.id}`,
      method: 'DELETE'
    });
  }

  async waitRouteInfoResetProcessing(processId) {
    const process = await fetchApi({
      endpoint: `admin/jobs/reset_routes/${processId}/status`
    });
    if (process.status !== 'PROCESSING') {
      return process.status;
    }
    await wait(5000);
    return this.waitRouteInfoResetProcessing(processId);
  }

  async assignTo(jobs, assignTo) {
    const jobsBulkUpdate = { data: [] };
    jobs.forEach((job) => {
      const jobUpdate = {
        do_number: job.do_number,
        date: job.date,
        data: { assign_to: assignTo }
      };
      jobsBulkUpdate.data.push(jobUpdate);
    });
    const updateResponse = await fetchApi({
      endpoint: 'jobs',
      method: 'PUT',
      body: JSON.stringify(jobsBulkUpdate)
    });
    return updateResponse.data;
  }

  async getAssignees(params) {
    return fetchApi({ endpoint: 'admin/assignees', params });
  }

  async listJobs(params) {
    const { jobType } = params;
    return fetchApi({ endpoint: `admin/jobs/${jobType}/list`, params });
  }

  async getStatusOverview(params) {
    const { jobType } = params;
    return fetchApi({ endpoint: `admin/jobs/${jobType}/overview`, params });
  }

  async searchBySkuOrSerialNumber(params) {
    return fetchApi({ endpoint: 'admin/jobs/search/by_item_sku_or_serial', params });
  }

  async getDepots() {
    return fetchApi({ endpoint: 'jobs/depots' });
  }
}

export const jobApi = new JobApi();
