import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { jobApi } from '../api/job';

export const DepotsContext = createContext({
  depots: [],
});

export const DepotsProvider = (props) => {
  const { children } = props;
  const [depots, setDepots] = useState([]);

  useEffect(() => {
    jobApi
      .getDepots()
      .then((response) => {
        setDepots(response);
      });
  }, []);

  return (
    <DepotsContext.Provider
      value={{
        depots,
      }}
    >
      {children}
    </DepotsContext.Provider>
  );
};

DepotsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const DepotsConsumer = DepotsContext.Consumer;
export const useDepots = () => useContext(DepotsContext);
