import { Auth } from 'aws-amplify';

const fetchApi = async ({
  endpoint,
  method = 'GET',
  params = {},
  body = undefined,
  httpResponse = false,
  validateStatusFn = null // (status) => bool
}) => {
  const user = await Auth.currentAuthenticatedUser();
  const searchParams = new URLSearchParams(params);
  const url = `${process.env.REACT_APP_FETCH_API_URL}/${endpoint}${searchParams ? `?${searchParams}` : ''}`;
  const response = await fetch(
    url,
    {
      method,
      body,
      headers: {
        'X-API-KEY': user.attributes['custom:FETCH_API_TOKEN'],
        'Content-Type': 'application/json'
      },
    }
  );

  if (validateStatusFn != null) {
    if (validateStatusFn(response.status)) {
      return httpResponse ? response : response.json();
    }

    throw new Error(`Request failed with status code ${response.status}`);
  }

  return httpResponse ? response : response.json();
};

export default fetchApi;
