import { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, List } from '@material-ui/core';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OverviewIcon from '@mui/icons-material/DonutSmall';
import LockClockIcon from '@mui/icons-material/LockClock';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { PersonSearch as PersonSearchIcon } from '@material-ui/icons';
import { DashboardNavbarMenuItem } from './dashboard-navbar-menu-item';
import { Cog as CogIcon } from '../icons/cog';
import { ChartBar as ControlTowerIcon } from '../icons/chart-bar';
import { OfficeBuilding as DepotsIcon } from '../icons/office-building';
import { CustomChartPie as OptimizerGroupsIcon } from '../icons/custom-chart-pie';
import { ChartSquareBar as OptimizationsIcon } from '../icons/chart-square-bar';
import { UserGroup as GroupsIcon } from '../icons/user-group';
import { Star } from '../icons/star';

const items = [
  {
    icon: CogIcon,
    title: 'Account',
    items: [
      {
        href: '/account',
        title: 'General Settings'
      },
      {
        href: '/account/notifications',
        title: 'Notifications'
      }
    ]
  },
  {
    icon: ControlTowerIcon,
    title: 'Control tower',
    href: '/'
  },
  {
    icon: OverviewIcon,
    title: 'Daily overview',
    href: '/overview'
  },
  {
    icon: OptimizationsIcon,
    title: 'Optimizations',
    href: '/optimizations'
  },
  {
    icon: OptimizerGroupsIcon,
    title: 'Optimizer groups',
    href: '/optimizer-groups'
  },
  {
    icon: GroupsIcon,
    title: 'Groups',
    href: '/groups'
  },
  {
    icon: DepotsIcon,
    title: 'Depots',
    href: '/depots'
  },
  {
    icon: LockClockIcon,
    title: 'Postal Code Ranges',
    href: '/postal-code-ranges'
  },
  {
    icon: LocalShippingIcon,
    title: 'Vehicles',
    items: [
      {
        href: '/vehicles',
        title: 'List'
      },
      {
        href: '/vehicles/overview',
        title: 'Overview'
      }
    ]
  },
  {
    icon: LockClockIcon,
    title: 'Time Windows',
    href: '/time-windows'
  },
  {
    icon: PictureAsPdfIcon,
    title: 'Utils',
    href: '/utils'
  },
  {
    icon: PersonSearchIcon,
    title: 'Drivers',
    href: '/drivers'
  },
  {
    icon: AccountCircle,
    title: 'Users',
    href: '/users'
  },
  {
    icon: ShoppingCartIcon,
    title: 'MyFetch',
    items: [
      {
        href: '/postcodes',
        title: 'Postcodes'
      }
    ]
  },
  {
    icon: Star,
    title: 'Job events',
    href: '/job-events'
  }
];

export const DashboardNavbarMenu = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }

      if (!activeItem) {
        const active = pathname.includes(item.href);
        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#2B2F3C',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw'
        }
      }}
    >
      <List>
        {items.map((item) => (
          <DashboardNavbarMenuItem
            active={activeItem?.title === item.title}
            activeHref={activeHref}
            key={item.title}
            onClose={onClose}
            onOpenItem={() => handleOpenItem(item)}
            open={openedItem?.title === item.title}
            {...item}
          />
        ))}
      </List>
    </Drawer>
  );
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
